import { Tooltip } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiGit,
} from "react-icons/di";
import {
  SiAuth0,
  SiCss3,
  SiFirebase,
  SiHtml5,
  SiMui,
  SiNextdotjs,
  SiRedux,
  SiStripe,
} from "react-icons/si";
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Tooltip title="HTML" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiHtml5 />
        </Col>
      </Tooltip>
      <Tooltip title="CSS" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiCss3 />
        </Col>
      </Tooltip>
      <Tooltip title="JavaScript" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <DiJavascript1 />
        </Col>
      </Tooltip>
      <Tooltip title="Node" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <DiNodejs />
        </Col>
      </Tooltip>
      <Tooltip title="React" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <DiReact />
        </Col>
      </Tooltip>
      <Tooltip title="Next Js" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiNextdotjs />
        </Col>
      </Tooltip>
      <Tooltip title="Redux" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiRedux />
        </Col>
      </Tooltip>
      <Tooltip title="Mongo" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <DiMongodb />
        </Col>
      </Tooltip>
      <Tooltip title="Git" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <DiGit />
        </Col>
      </Tooltip>
      <Tooltip title="Firebase" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiFirebase />
        </Col>
      </Tooltip>
      <Tooltip title="Auth0" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiAuth0 />
        </Col>
      </Tooltip>
      <Tooltip title="Stripe" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiStripe />
        </Col>
      </Tooltip>
      <Tooltip title="Mui" arrow>
        <Col xs={4} md={2} className="tech-icons">
          <SiMui />
        </Col>
      </Tooltip>
    </Row>
  );
}

export default Techstack;
